import React, { Component } from 'react';
import { Fade, Reveal } from 'react-reveal';
import './assets/css/race-resume.css'

class RaceResume extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className='race_resume_container'>
                <Fade duration={2000}>
                    <Reveal effect='fadeInDown' duration={1500}>
                        <div className='image_container'>
                            <img className='race_resume_image' src={require ("./image/race-resume/Valmont.jpeg")} alt=""/>
                        </div>
                    </Reveal>
                    <div className='title_name'>
                        <Reveal effect='fadeInLeft' duration={3000}>
                            BRIAN BOUCHARD
                        </Reveal>
                    </div>
                    <div className='title_resume'>
                        <Reveal effect='fadeInBottom' duration={3000}>
                            My
                            <br/>Athletic
                            <br/>Resume
                        </Reveal>
                    </div>
                    <div className='resume_details_container'>
                        <h2 className='results_group'>SERIES RESULTS</h2>
                        <div className='year_container'>
                            <h3>2022</h3>
                            <div className='event'>8th, SHIMANO GRX CycloX, Cyclocross, MM 40+ Cat 2</div>
                        </div>
                        <div className='year_container'>
                            <h3>2021</h3>
                            <div className='event'>2nd, SHIMANO GRX CycloX, Cyclocross, MM 40+ Cat 3</div>
                        </div>
                        <div className='year_container2'>
                            <h3>2019</h3>
                            <div className='event'>1st, SHIMANO GRX CycloX, Cyclocross, MM 40+ Cat 4</div>
                        </div>
                        <div className='year_container'>
                            <h3>2016</h3>
                            <div className='event'>3rd, Rocky Mountain Endurance Series, XC MTB, XC Men 30-39</div>
                        </div>
                        <div className='year_container'>
                            <h3>2013</h3>
                            <div className='event'>1st, Rocky Mountain Endurance Series, XC MTB, XC Half Marathon Men 30-39</div>
                        </div>
                        <div/>
                        <h2 className='results_group'>INDIVIDUAL RESULTS</h2>
                        <div className='year_container'>
                            <h3>2024</h3>
                                <div className='event'>6th, Mad Gravel (100 Miles), Gravel, Men 40-49</div>
                                <div className='event'>9th, Foco Fondo, Gravel (118 Miles), Men 40-49</div>
                                <div className='event'>9th, The Rad (110 Miles), Gravel, Men 40-49</div>
                                <div className='event'>9th, Colorado State Championships, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>10th, Shimano GRX CycloX - Westminster, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>17th, Big Sugar (105 Miles), Gravel, Men 40-49</div>
                                <div className='event'>18th, USA Cycling Gravel National Championships (131 Miles), Gravel, Men 40-49</div>
                        </div>
                        <div className='year_container'>
                            <h3>2023</h3>
                                <div className='event'>6th, CO2UT - Utahraptor 125-Miler, Gravel, Men 40-49</div>
                                <div className='event'>12th, The Bear, XC MTB, Men 40-49, Cat 1</div>
                                <div>-------</div>
                                <div>Injured / Recovering from Surgery</div>
                        </div>
                        <div className='year_container'>
                            <h3>2022</h3>
                                <div className='event'>1st, Mad Gravel, Ultra 123 Miles, Gravel, Men 40-49 (7th Overall)</div>
                                <div className='event'>3rd, Gravel Locos Pueblo - 150, Gravel, Men 40-49 (20th Overall)</div>
                                <div className='event'>7th, Fandango, XC MTB, Cat 1 Men 40-49   </div>
                                <div className='event'>8th, Shimano GRX CycloX - Longmont, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>8th, Shimano GRX CycloX - Interlocken, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>9th, Ned Gravel, Gravel, Men 40-49</div>
                                <div className='event'>10th, Colorado State Cyclocross Championships, XC MTB, Cat 1 Men 40-44</div>
                                <div className='event'>10th, Shimano GRX CycloX - Louisville, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>12th, Shimano GRX CycloX - Harlow Platts, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>16th, 2022 USAC MTB Nationals: Cross-Country, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>17th, Shimano GRX CycloX - Westminster, Cyclocross, MM 40+ Cat 2</div>
                                <div className='event'>24th, SBT GRVL (Black Course - 140), Gravel, Men 40-49 (37th Overall)</div>
                        </div>
                        <div className='year_container'>
                            <h3>2021</h3>
                                <div className='event'>1st, Shimano GRX CycloX - Parker, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>2nd, GoPro Mountain Games, XC MTB, Men 35+ Sport / Cat 2</div>
                                <div className='event'>3rd, Colorado State Cyclocross Championships, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>3rd, Shimano GRX CycloX - Longmont, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>3rd, Winter Park Race #2 - Race Rendezvous, XC MTB, Expert / Cat 1</div>
                                <div className='event'>3rd, Shimano GRX CycloX - Westminster, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>4th, Shimano GRX CycloX - Interlocken, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>4th, Shimano GRX CycloX - Valmont, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>4th, Mad Gravel, Ultra 123 Miles, Gravel, Men 40-49 (13th Overall)</div>
                                <div className='event'>5th, Shimano GRX CycloX - Broomfield, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>6th, Winter Park Race #3 - Kind of the Rockies, XC MTB, Expert / Cat 1</div>
                                <div className='event'>13th, Leadville Silver Rush 50 MTB, XC MTB, Men 40-49 (37th Overall)</div>
                                <div className='event'>53rd, Leadville 100 MTB (Sub-9hr Big Belt Buckle), XC MTB, Men 40-49</div>
                        </div>
                        <div className='year_container'>
                            <h3>2020</h3>
                                <div className='event'>1st, Shimano GRX CycloX - Parker, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>3rd, US Open of Cyclocross, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>3rd, Shimano GRX CycloX - Parker 2.0, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>6th, Shimano GRX CycloX - Broomfield, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>6th, Shimano GRX CycloX - Interlocken, Cyclocross, MM 40+ Cat 3</div>
                                <div className='event'>9th, Shimano GRX CycloX - Valmont, Cyclocross, SM 4</div>
                        </div>
                        <div className='year_container'>
                            <h3>2019</h3>
                                <div className='event'>1st, Shimano GRX CycloX - Westminster, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>4th, Shimano GRX CycloX - Sienna Lake, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>4th, Shimano GRX CycloX - Interlocken, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>5th, Shimano GRX CycloX - Louisville, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>10th, Shimano GRX CycloX - Valmont, Cyclocross, MM 40+ Cat 4</div>
                                <div className='event'>13th, Shimano GRX CycloX - Harlow Platts, Cyclocross, MM 40+ Cat 4</div>
                        </div>
                        <div className='year_container'>
                            <h3>2018</h3>
                                <div className='event'>1st, CCCX XC MTB Race #7, XC MTB, MM 35-44 Cat 2</div>
                                <div className='event'>5th, SUBARU SEA OTTER CLASSIC, XC MTB, MM 40-42 Cat 2</div>
                        </div>
                        <div className='year_container'>
                            <h3>2017</h3>
                                <div className='event'>1st, CCCX XC MTB Race #6, XC MTB, MM 35-44 Cat 2</div>
                                <div className='event'>4th, Annadel Classic, XC MTB, Men Cat 2</div>
                                <div className='event'>6th, Downieville Classic, XC MTB, Men 31-40, Cat 2</div>
                        </div>
                        <div className='year_container'>
                            <h3>2016</h3>
                                <div className='event'>3rd, Ridgeline Rampage, XC MTB, XC Men 30-39</div>
                                <div className='event'>5th, Copper Crush, XC MTB, XC Men 30-39</div>
                                <div className='event'>7th, Cross of the North, Cyclocross, SM4</div>
                                <div className='event'>7th, Breckenridge 32, XC MTB, XC Men 30-39</div>
                                <div className='event'>9th, Peaceful Valley Derby, XC MTB, XC Men 30-39</div>
                                <div className='event'>10th, Battle the Bear, XC MTB, XC Men 30-39</div>
                                <div className='event'>13th, Shimano GRX CycloX - Harlow Platts, Cyclocross, SM 4</div>
                                <div className='event'>22nd, US Open of Cyclocross, Cyclocross, SM4</div>
                                <div className='event'>26th, Gunnison Half Growler, XC MTB, Open Men 30-39</div>
                        </div>
                        <div className='year_container'>
                            <h3>2014</h3>
                                <div className='event'>1st, Boulder Cup, Cyclocross, SM5</div>
                                <div className='event'>4th, GoPro Mountain Games, XC MTB, Veteran Men 35+ Cat 2</div>
                                <div className='event'>7th, Indian Creek, XC MTB, XC Men 30-39</div>
                                <div className='event'>7th, Primalpalooza, Cyclocross, MM 35+ Cat 4</div>
                                <div className='event'>8th, Steamboat Stinger / Emerald Mountain Epic (52 Miles), XC MTB, Citizen</div>
                                <div className='event'>8th, Shimano GRX CycloX - Interlocken, Cyclocross, MM 35+ Cat 4</div>
                                <div className='event'>10th, Breckenridge 32, XC MTB, XC Men 30-39</div>
                                <div className='event'>12th, Winter Park Epic Single Track Race #4, XC MTB, Expert / Cat 1</div>
                                <div className='event'>14th, Whiskey Offroad 25, XC MTB, Mens Open</div>
                                <div className='event'>17th, Shimano GRX CycloX - Xiling, Cyclocross, MM 35+ Cat 4</div>
                                <div className='event'>17th, Shimano GRX CycloX - Flatirons, Cyclocross, MM 35+ Cat 4</div>
                                <div className='event'>29th, Rocky Mountain Cyclocross Championships, Cyclocross, MM 35+ Cat 4</div>
                                <div className='event'>31st, Shimano GRX CycloX - Valmont, Cyclocross, MM 35+ Cat 4</div>
                        </div>
                        <div className='year_container'>
                            <h3>2013</h3>
                                <div className='event'>7th, Ridgeline Rampage, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>7th, Indian Creek, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>7th, Peaceful Valley Derby, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>7th, Snake River, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>10th, Battle the Bear, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>10th, Breckenridge 32, XC MTB, XC Half Marathon Men 30-39</div>
                        </div>
                        <div className='year_container'>
                            <h3>2012</h3>
                                <div className='event'>4th, Ridgeline Rampage, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>8th, Battle the Bear, XC MTB, XC Half Marathon Men 30-39</div>
                                <div className='event'>34th, GoPro Mountain Games, XC MTB, Men Sport / Cat 2</div>
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default RaceResume;
